<template>
  <div class="yimiao">
    <span>请按提示进行下载!!!!</span>
    <div>
      <iframe :src="pdf" width="100%" height="100%" style="display: none">
        <a href="/test.pdf" rel="external nofollow"></a>
      </iframe>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
Vue.use(Toast);
import { SearchReport, WxExportReportToPdf } from "@/api/dayao";
// import Pdfh5 from "pdfh5";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      datafrom: {
        name: "",
        certNumber: "",
      },
      list: [],
      pdf: "",
    };
  },
  created() {
    this.updata(this.$route.params.id);
  },
  methods: {
    // 下载报告
    updata(row) {
      console.log(row);
      WxExportReportToPdf({ crId: row }).then((res) => {
        if (res.data.code == 0) {
          this.pdf = config.apiHttpsUrl + res.data.data;
          Dialog.alert({
            title: "提示",
            message: "请按提示进行下载",
          }).then(() => {
            this.$router.go(-1);
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "操作失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}
.yimiao .inputFrom {
  margin: 15px 8%;
}
.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.yimiao .buttons {
  text-align: center;
}
.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>